import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  MaschinenlaufplanDaten,
  VPUnterschriftenDaten,
} from 'wacoplast_wws__api';
import styles from './VPUnterschriftenPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ROWS_PER_PAGE = 27;
const KARTONS_PER_PAGE = ROWS_PER_PAGE * 2;

export function VPUnterschriftenPrint(props: PropsWithServices<{ data?: { vpUnterschriften: VPUnterschriftenDaten, maschinenlaufplan: MaschinenlaufplanDaten }, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <VPUnterschriften maschinenlaufplan={props.data.maschinenlaufplan} vpUnterschriften={props.data.vpUnterschriften} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return VPUnterschriftenCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function VPUnterschriftenCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  return <VPUnterschriften vpUnterschriften={payload.vp_unterschriften} maschinenlaufplan={payload.maschinenlaufplan} />;
}

function VPUnterschriften(props: { vpUnterschriften: VPUnterschriftenDaten, maschinenlaufplan: MaschinenlaufplanDaten, hidePrintButton?: boolean }): JSX.Element {
  const isKarton = props.maschinenlaufplan.karton_geschlossen !== undefined;
  const amountOfEntries = isKarton
    ? (props.maschinenlaufplan.vpes_pro_stapel_1 * props.maschinenlaufplan.anzahl_stapel_1) + ((props.maschinenlaufplan.vpes_pro_stapel_2 ?? 0) + (props.maschinenlaufplan.anzahl_stapel_2 ?? 0))
    : props.maschinenlaufplan.anzahl_stapel_1 + (props.maschinenlaufplan.anzahl_stapel_2 ?? 0);
  const amountOfPages = Math.ceil(amountOfEntries / KARTONS_PER_PAGE);

  return (
    <>
      {new Array(amountOfPages).fill(0).map((_, index) => {
        return (
          <VPUnterschriftenPage
            key={index}
            maschinenlaufplan={props.maschinenlaufplan}
            vpUnterschriften={props.vpUnterschriften}
            hidePrintButton={index !== 0}
            firstKartonNumber={index * KARTONS_PER_PAGE + 1}
            lastKartonNumber={Math.min(index * KARTONS_PER_PAGE + KARTONS_PER_PAGE, amountOfEntries)}
          />
        );
      })}
    </>
  );
}

function VPUnterschriftenPage(props: { hidePrintButton?: boolean, vpUnterschriften: VPUnterschriftenDaten, maschinenlaufplan: MaschinenlaufplanDaten, firstKartonNumber: number, lastKartonNumber: number }): JSX.Element {
  const vpUnterschriften = props.vpUnterschriften;

  return (
    <div className={styles.vpunterschriften_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <HTMLTable className={styles.vpunterschriften_table}>
        <thead>
          <tr>
            <th colSpan={7} className={styles.page_header}>MLP: VP-Unterschriften</th>
          </tr>
          <tr />
          <tr>
            <th colSpan={7} className={styles.table_header}>PAU-Nr.: {vpUnterschriften.produktionsauftrag_nummer}, BLM: {vpUnterschriften.blasmaschine_nummer}, {vpUnterschriften.flaschen_typ_nummer} {vpUnterschriften.flaschen_typ_bezeichnung} {vpUnterschriften.farbe_bezeichnung} {vpUnterschriften.bestellnummer} {props.maschinenlaufplan.empfaenger_name}</th>
          </tr>
          <tr>
            <th className={styles.nummer_column_header}>{props.maschinenlaufplan.karton_geschlossen !== undefined ? 'Karton' : 'Stapel'}<br />Nr.</th>
            <th className={styles.datum_verpacker_column_header}>Datum</th>
            <th className={styles.datum_verpacker_column_header}>Verpacker<br />Name</th>
            <th className={styles.empty_column}> </th>
            <th className={styles.nummer_column_header}>{props.maschinenlaufplan.karton_geschlossen !== undefined ? 'Karton' : 'Stapel'}<br />Nr.</th>
            <th className={styles.datum_verpacker_column_header}>Datum</th>
            <th className={styles.datum_verpacker_column_header}>Verpacker<br />Name</th>
          </tr>
        </thead>
        <tbody>
          { new Array(ROWS_PER_PAGE).fill(0).map((_, index) => {
            const nummerLeft = props.firstKartonNumber + index;
            const nummerRight = props.firstKartonNumber + ROWS_PER_PAGE + index;
            return (
              <tr key={index}>
                <th>{nummerLeft <= props.lastKartonNumber ? nummerLeft : ''}</th>
                <td> </td>
                <td> </td>
                <td className={styles.empty_column}> </td>
                <th>{nummerRight <= props.lastKartonNumber ? nummerRight : ''}</th>
                <td> </td>
                <td> </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </div>
  );
}

import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  LOCALE_DE,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  MaschinenlaufplanDaten,
  Rueckmeldung2Daten,
} from 'wacoplast_wws__api';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import styles from './Rueckmeldung2Print.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function Rueckmeldung2Print(props: PropsWithServices<{ data?: { maschinenlaufplan: MaschinenlaufplanDaten, rueckmeldung2: Rueckmeldung2Daten }, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Rueckmeldung2 {...props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return Rueckmeldung2CustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function Rueckmeldung2CustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  return <Rueckmeldung2 maschinenlaufplan={payload.maschinenlaufplan} rueckmeldung2={payload.rueckmeldung2} />;
}

function Rueckmeldung2(props: { rueckmeldung2: Rueckmeldung2Daten, maschinenlaufplan: MaschinenlaufplanDaten, hidePrintButton?: boolean }): JSX.Element {
  const { rueckmeldung2, maschinenlaufplan } = props;

  return (
    <div className={styles.rueckmeldung2_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <HTMLTable className={styles.rueckmeldung2_table}>
        <thead>
          <tr className={styles.small}>
            <td>MLP: Rückmeldung 2</td>
            <th colSpan={2} className={styles.center}>Dieses Blatt an letzte Palette heften</th>
            <td className={styles.right}>{new Date().toLocaleDateString(LOCALE_DE, { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
          </tr>
          <tr className={styles.small}>
            <td colSpan={3} />
            <td className={styles.right}>{new Date().toLocaleTimeString(LOCALE_DE, { timeStyle: 'short' })}</td>
          </tr>
          <tr>
            <th colSpan={4} className={styles.fertige_produktion_header}>Fertige Produktion</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan={2}>PAU-Nr.:</th>
            <td colSpan={2}>{rueckmeldung2.produktionsauftrag_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2}>Bestell-Nr.:</th>
            <td colSpan={2}>{rueckmeldung2.kunde_bestell_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2}>Bestelldatum:</th>
            <td colSpan={2}>{formatDateString(rueckmeldung2.bestelldatum)}</td>
          </tr>
          <tr>
            <th colSpan={2}>Kunde:</th>
            <td colSpan={2}>{rueckmeldung2.kunde_name}</td>
          </tr>
          <tr>
            <th colSpan={2} className={styles.center}>Empfänger:</th>
            <td colSpan={2}>{rueckmeldung2.empfaenger_name ?? rueckmeldung2.kunde_name}</td>
          </tr>
          <tr className={styles.no_border_y}>
            <th colSpan={2}>FL-Typ:</th>
            <td colSpan={2}>{maschinenlaufplan.flaschentyp_bezeichnung}</td>
          </tr>
          <tr className={styles.no_border_y}>
            <th colSpan={2} />
            <td className={styles.border_y} />
            <td className={styles.border_y}>{rueckmeldung2.farbe_bezeichnung}</td>
          </tr>
          <tr className={styles.no_border_y}>
            <th colSpan={2} className={styles.volumen_header}>Vol.[ml] / Halsteil</th>
            <td>{formatNumber(rueckmeldung2.volumen)}</td>
            <td className={styles.normal}>{rueckmeldung2.halsteil_bezeichnung}</td>
          </tr>
          <tr>
            <th colSpan={2}>KU-Artikel Nr.:</th>
            <td colSpan={2}>{rueckmeldung2.artikel_nummer_kunde}</td>
          </tr>
          <tr>
            <th colSpan={2}>Bestellmenge:</th>
            <td colSpan={2}>{formatNumber(rueckmeldung2.menge_produktionsauftrag)}</td>
          </tr>
          <tr>
            <th colSpan={2}>Anzahl Paletten:</th>
            <td colSpan={2} className={styles.tall} />
          </tr>
          <tr>
            <th colSpan={2}>Verpackungsart:</th>
            <td colSpan={2} className={styles.normal}>{rueckmeldung2.verpackungs_einheit_bezeichnung}</td>
          </tr>
          <tr>
            <th colSpan={2}>Gesamtstückzahl:</th>
            <td colSpan={2} className={styles.tall} />
          </tr>
          <tr>
            <th colSpan={2}>Datum Produktionsende:</th>
            <td colSpan={2} className={styles.tall} />
          </tr>
          <tr className={styles.no_border_y}>
            <th className={styles.bemerkungen}>Bemerkungen:</th>
          </tr>
        </tbody>
        <tfoot>
          <tr className={styles.large}>
            <th colSpan={4} className={styles.stueckzahl_geprueft_gezaehlt}>Stückzahl geprüft & gezählt:</th>
          </tr>
          <tr />
          <tr className={styles.datum_name_stueckzahl}>
            <th>Datum:</th>
            <th>Name:</th>
            <th colSpan={2}>Unterschrift:</th>
          </tr>
          <tr>
            <td />
            <td />
            <td />
          </tr>
        </tfoot>
      </HTMLTable>
    </div>
  );
}

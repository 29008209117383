import { Card, H3 } from '@blueprintjs/core';
import styles from './FormCard.module.scss';

export type FormCardProps = React.PropsWithChildren<{
  title?: string;
  footer?: JSX.Element;
}>;

export const FormCard = (props: FormCardProps): JSX.Element => {
  return (
    <div className={styles.form_card}>
      <Card>
        <H3>{props.title}</H3>
        {props.children}
        <div className={styles.form_card__footer}>
          {props.footer}
        </div>
      </Card>
    </div>
  );
};

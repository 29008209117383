import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  QHinweisEntry,
  QHinweiseDaten,
} from 'wacoplast_wws__api';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import styles from './QHinweisePrint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';

export function QHinweisePrint(props: PropsWithServices<{ data?: QHinweiseDaten, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <QHinweise data={props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return QHinweiseCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function QHinweiseCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const { qhinweise } = payload;

  return <QHinweise data={qhinweise} />;
}

function QHinweise(props: { data: QHinweiseDaten, hidePrintButton?: boolean }): JSX.Element {
  const qhinweise = props.data;

  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const [tableRows, setTableRows] = useState<Array<Array<QHinweisEntry>> | null>(null);

  useEffect(() => {
    const maxTotalRowHeightPerPage = 569;
    let heightCurrentPage = 0;
    const tableRowGroups: Array<Array<QHinweisEntry>> = [[]];

    if (!bodyRef.current) {
      return;
    }

    for (let i = 0; i < bodyRef.current.children.length; i++) {
      const child = bodyRef.current.children.item(i);
      if (!child) {
        continue;
      }
      if (heightCurrentPage + child.clientHeight > maxTotalRowHeightPerPage) {
        tableRowGroups.push([]);
        heightCurrentPage = 0;
      }
      tableRowGroups[tableRowGroups.length - 1].push(qhinweise.hinweise[i]);
      heightCurrentPage += child.clientHeight;
    }

    setTableRows(tableRowGroups);
  }, []);

  if (tableRows === null) {
    return <QHinweisePage hinweise={qhinweise} hidePrintButton={props.hidePrintButton} bodyRef={bodyRef} />;
  } else {
    return (
      <>
        {tableRows.map((rows, index) => {
          return <QHinweisePage key={index} hinweise={{ ...qhinweise, hinweise: rows }} hidePrintButton={index !== 0} />;
        })}
      </>
    );
  }
}

function QHinweisePage(props: { hinweise: QHinweiseDaten, bodyRef?: React.RefObject<HTMLTableSectionElement>, hidePrintButton?: boolean }): JSX.Element {
  const qhinweise = props.hinweise;

  return (
    <div className={styles.qhinweise_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <HTMLTable className={styles.qhinweise_table}>
        <thead>
          <tr>
            <th colSpan={2}>KUNDE:</th>
            <td>{qhinweise.kunde_name}</td>
          </tr>
          <tr>
            <th colSpan={2}>FL-TYP:</th>
            <td>{qhinweise.flaschen_typ_bezeichnung}</td>
            <td>{qhinweise.flaschen_typ_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2}>PAU-NR.:</th>
            <td>{qhinweise.produktionsauftrag_nummer}</td>
          </tr>
          <tr>
            <th colSpan={8} className={styles.qualitaets_information_header}>Qualitäts-Information</th>
          </tr>
          <tr>
            <td colSpan={4} className={styles.qualitaets_information} />
            <td className={styles.empty_cell} />
            <td colSpan={3} className={styles.qualitaets_information} />
          </tr>
          <tr className={styles.empty_row} />
          <tr>
            <th className={styles.lfd_nr_header}>lfd. Nr.</th>
            <th className={styles.datum_header}>Datum</th>
            <th className={styles.text_qs_hinweis}>Hinweise von QS (Reklamationen)</th>
            <th className={styles.text_korrektur}>durchgeführte Korrekturmaßnahmen</th>
            <th className={styles.empty_cell} />
            <th className={styles.lfd_nr_header}>lfd. Nr.</th>
            <th className={styles.datum_header}>Datum</th>
            <th className={styles.text_prozesshinweis}>Hinweis zur Prozessführung</th>
          </tr>
        </thead>
        <tbody ref={props.bodyRef}>
          {[...qhinweise.hinweise].map((qhinweis: QHinweisEntry, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatDateString(qhinweis.datum)}</td>
              <td className={styles.text_cell}>{qhinweis.hinweis}</td>
              <td className={styles.text_cell}>{qhinweis.korrekturmanahme}</td>
              <td className={styles.empty_cell} />
              <td />
              <td />
              <td />
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
}

import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  withServices,
} from '../../../infrastructure';
import { MaschinenlaufplanPrint } from './MLPPrint';
import { Dialog, Spinner } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import * as html2pdf from 'html2pdf.js';
import { PDFDocument } from 'pdf-lib';

import './AllArbeitspapiere.module.scss';
import { StellblattPrint } from './StellblattPrint';
import {
  AbmusterungPrint,
  BegleitdokumentPrint,
  EtikettKartonPrint,
  EtikettTrayPrint,
  GewichtskontrollePrint,
  PruefprotokollPrint,
  QHinweisePrint,
  Rueckmeldung1Print,
  Rueckmeldung2Print,
  VPUnterschriftenPrint,
} from '.';
import { useEffect } from 'react';

export function AllArbeitspapierePrint(props: PropsWithServices<{ getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return withServices(AllArbeitspapiere);
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function AllArbeitspapiere(props: PropsWithServices<CustomFormProps<ArbeitspapiereDatenLadenResult>>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  const generatePdf = async (): Promise<void> => {
    const mergePdfs = async (pdfsToMerges: ArrayBuffer[]): Promise<string> => {
      const mergedPdf = await PDFDocument.create();
      const actions = pdfsToMerges.map(async pdfBuffer => {
        const pdf = await PDFDocument.load(pdfBuffer);
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page: any, index: number) => {
          // Remove last page from vertical pdf, because it's always empty
          if (index === copiedPages.length - 1 && pdf.getPage(index).getWidth() < pdf.getPage(index).getHeight()) {
            return;
          }
          mergedPdf.addPage(page);
        });
      });
      await Promise.all(actions);
      const mergedPdfFile = await mergedPdf.saveAsBase64();
      return mergedPdfFile;
    };

    const pdfPromises = [
      html2pdf()
        .set({ margin: 0, pagebreak: { mode: 'avoid-all' } })
        .from(document.getElementById('portraitPrint'))
        .outputPdf('arraybuffer'),
      html2pdf()
        .set({ margin: 0, pagebreak: { mode: 'avoid-all' }, jsPDF: { format: 'a4', orientation: 'landscape' } })
        .from(document.getElementById('landscapePrint'))
        .outputPdf('arraybuffer'),
    ];

    const pdfs = await Promise.all(pdfPromises);
    const result = await mergePdfs(pdfs);

    function base64PDFToBlobUrl(base64: string): string {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      const blob = new Blob([arr], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    }

    const url = base64PDFToBlobUrl(result);
    window.opener.postMessage({ type: 'pdfAsBlob', data: url }, window);
    window.close();
  };

  useEffect(() => generatePdf() as any, []);

  return (
    <>
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte. Arbeitspapiere werden erzeugt.'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
      <div id='portraitPrint'>
        <MaschinenlaufplanPrint {...props} data={payload.maschinenlaufplan} />
        <div className='pagebreak'>
          <StellblattPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, stellblatt: payload.stellblatt }} />
        </div>
        <div className='pagebreak'>
          <PruefprotokollPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, pruefprotokoll: payload.pruefprotokoll }} />
        </div>
        <div className='pagebreak'>
          <GewichtskontrollePrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, gewichtskontrolle: payload.gewichtstoleranz }} />
        </div>
        {props.tokenPayload.begleitdokument &&
        <div className='pagebreak'>
          <BegleitdokumentPrint {...props} data={payload.begleitdokument} />
        </div>
        }
        <div className='pagebreak'>
          <Rueckmeldung2Print {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, rueckmeldung2: payload.rueckmeldung2 }} />
        </div>
        {props.tokenPayload.etikett_karton &&
          <div className='pagebreak'>
            <EtikettKartonPrint {...props} data={payload.etikett_karton} />
          </div>
        }
        {props.tokenPayload.abmusterung &&
          <div className='pagebreak'>
            <AbmusterungPrint {...props} data={payload.abmusterung} />
          </div>
        }
        <div className='pagebreak'>
          <VPUnterschriftenPrint {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, vpUnterschriften: payload.vp_unterschriften }} />
        </div>
      </div>
      <div id='landscapePrint'>
        <div className='pagebreak'>
          <Rueckmeldung1Print {...props} data={{ maschinenlaufplan: payload.maschinenlaufplan, rueckmeldung1: payload.rueckmeldung1 }} />
        </div>
        {props.tokenPayload.etikett_tray &&
          <div className='pagebreak'>
            <EtikettTrayPrint {...props} data={{ etikettTray: payload.etikett_tray!, maschinenlaufplan: payload.maschinenlaufplan }} />
          </div>
        }
        {payload.qhinweise.hinweise.length > 0 &&
        <div className='pagebreak'>
          <QHinweisePrint {...props} data={payload.qhinweise} />
        </div>
        }
      </div>
      {props.tokenPayload.packordnung &&
        <div className='pagebreak'>
          Packordnung
        </div>
      }
    </>
  );
}

/* eslint-disable react/display-name */
import styles from './flaschentypVerwalten.module.scss';
import { Blindenzeichen, FlaschenTyp } from 'wacoplast_wws__api';
import { StammdatenFieldEditorComplexRendererProps, TextEditor, UseFetchLikeServiceFunctionResult } from '../../infrastructure';


export function makeTextEditorWithSublabelForBLZRenderer(dataProvider: UseFetchLikeServiceFunctionResult<Array<Blindenzeichen>>, placeholder?: string, disabled?: boolean): any {
  return (props: StammdatenFieldEditorComplexRendererProps<FlaschenTyp>) => {
    const selectedBlindenzeichenDatabaseIDs = props.stammdatenEntity.moegliche_blindenzeichen.map(entry => entry.blindenzeichen_database_id);
    const selectedBlindenzeichen = selectedBlindenzeichenDatabaseIDs.length > 0 ? dataProvider.data?.filter((entry) => (selectedBlindenzeichenDatabaseIDs.includes(entry.database_id))) ?? [] : [];
    const selectedBlindenzeichenNummern = selectedBlindenzeichen?.map(blindenzeichen => Number(blindenzeichen.nummer));
    const includesOhneBLZ = selectedBlindenzeichenNummern.some(nummer => nummer === 1);
    const geradeUngeradeString = includesOhneBLZ ? 'ungeraden' : 'geraden';
    const blzHinweisString = `Nummer sollte auf einer ${geradeUngeradeString} Ziffer enden.`;

    const nummerIsNumeric = !isNaN(Number(props.stammdatenEntity.nummer));
    const nummerIsEven = nummerIsNumeric && Number(props.stammdatenEntity.nummer) % 2 === 0;

    const renderHinweis = selectedBlindenzeichen.length > 0 && nummerIsNumeric && nummerIsEven === includesOhneBLZ;

    return (
      <>
        <TextEditor
          value={props.stammdatenEntity.nummer ? String(props.stammdatenEntity.nummer) : null}
          disabled={props.disabled || disabled}
          placeholder={placeholder}
          onChange={(value) => {
            const nummerIsNumeric = !isNaN(Number(value));
            const previousNummerIsNumeric = !isNaN(Number(props.stammdatenEntity.nummer));
            const patch: Partial<FlaschenTyp> = {
              nummer: value ?? undefined,
            };
            if (
              (!nummerIsNumeric
                || (props.stammdatenEntity.nummer?.length > 0 && !previousNummerIsNumeric)
              )
              && (props.stammdatenEntity.nummer === props.stammdatenEntity.bezeichnung
                || !props.stammdatenEntity.bezeichnung?.length
              )
            ) {
              patch.bezeichnung = value ?? undefined;
            }

            props.onChange(patch);
          }}
        />
        {renderHinweis &&
          <span className={styles.sublabel}>
            {blzHinweisString}
          </span>
        }
      </>
    );
  };
}


import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  H4,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  MaschinenlaufplanDaten,
  PruefprotokollDaten,
} from 'wacoplast_wws__api';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PruefprotokollPrint.module.scss';
import logo from './assets/logo.png';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function PruefprotokollPrint(props: PropsWithServices<{ data?: { maschinenlaufplan: MaschinenlaufplanDaten, pruefprotokoll: PruefprotokollDaten }, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Pruefprotokoll maschinenlaufplan={props.data.maschinenlaufplan} pruefprotokoll={props.data.pruefprotokoll} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return PruefprotokollCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function PruefprotokollCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  return <Pruefprotokoll maschinenlaufplan={payload.maschinenlaufplan} pruefprotokoll={payload.pruefprotokoll} />;
}

function Pruefprotokoll(props: { maschinenlaufplan: MaschinenlaufplanDaten, pruefprotokoll: PruefprotokollDaten, hidePrintButton?: boolean }): JSX.Element {
  const { maschinenlaufplan, pruefprotokoll } = props;

  return (
    <div className={styles.pruefprotokoll_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <div className={styles.page_heading_container}>
        <H4 className={styles.page_heading}>PRÜF-PROTOKOLL</H4>
        <img src={logo} alt='' className={styles.logo} />
      </div>
      <HTMLTable className={styles.pruefprotokoll_table}>
        <tbody>
          <tr>
            <th colSpan={2}>PAU-Nr.:</th>
            <td colSpan={2} className={styles.fat_border}>{pruefprotokoll.produktionsauftrag_nummer}</td>
            <th>BLM:</th>
            <td className={styles.fat_border}>{pruefprotokoll.blasmaschine_nummer}</td>
            <td colSpan={7} className={styles.cell_without_border} />
          </tr>
          <tr>
            <th colSpan={2}>Kunde / Werkstoff:</th>
            <td colSpan={2} className={styles.fat_border_normal}>{pruefprotokoll.kunde_name}</td>
            <td className={styles.cell_without_border} />
            <td colSpan={2} className={styles.fat_border_normal}>{pruefprotokoll.werkstoff_bezeichnung}</td>
            <td colSpan={1} className={styles.farbe_header}>Farbe:</td>
            <td colSpan={3} className={styles.fat_border}>{maschinenlaufplan.farbe_bezeichnung}</td>
            <td className={styles.cell_without_border} />
          </tr>
          <tr>
            <th colSpan={2}>Artikel-Nr /-Bez.</th>
            <td colSpan={3} className={styles.fat_border_normal}>{pruefprotokoll.flaschen_typ_nummer}</td>
            <td colSpan={3} className={styles.fat_border_normal}>{pruefprotokoll.flaschen_typ_bezeichnung}</td>
            <td className={styles.cell_without_border} />
            <td className={styles.haeufigkeit_header}>Häufigkeit:</td>
            <td colSpan={3} className={styles.haeufigkeit_cell}>mind. 1 x pro Schicht</td>
          </tr>
          <tr>
            <th colSpan={2}>PAU-Menge:</th>
            <td colSpan={2} className={styles.fat_border_normal}>{formatNumber(pruefprotokoll.menge_produktionsauftrag)}</td>
            <td colSpan={2} className={styles.gewichtsoll_header}>Gewicht (SOLL):</td>
            <td colSpan={2} className={styles.gewichtsoll_cell}>{maschinenlaufplan.artikel_gewicht} &#177; {maschinenlaufplan.gewichtstoleranz?.toLocaleString('de')}</td>
            <td className={styles.in_ordnung}>i.O.</td>
            <td className={styles.in_ordnung}>n.i.O.</td>
            <td colSpan={3} className={styles.cell_without_border} />
          </tr>
          <tr>
            <th colSpan={7} className={styles.underline}>Prüfkriterien: siehe WN-503 &ldquo;Standard-Prüfkriterien&ldquo;</th>
            <td className={styles.cell_without_border} />
            <td className={styles.cell_without_border}><div className={styles.check_box}><div className={styles.checksign}>&#10003;</div></div></td>
            <td className={styles.cell_without_border}><div className={styles.check_box}><div className={styles.crosssign}>+</div></div></td>
            <td className={styles.cell_without_border}><div className={styles.pfeil}>↳</div></td>
            <td className={styles.cell_without_border}><div className={styles.pfeilstrich}>|</div></td>
            <td className={styles.paw}>s. PAW-330</td>
          </tr>
          <tr className={styles.bordered_header}>
            <th className={styles.datum_uhrzeit}>Datum<br />Uhrzeit</th>
            <th className={styles.pack_nummer}>Pack-<br />Nr.</th>
            <th>Ober-<br />fläche</th>
            <th>Farbe</th>
            <th>Form</th>
            <th className={styles.schweis_naehte}>Schweiß-<br />Nähte</th>
            <th>Hals-<br />Öffng.</th>
            <th>Kenn-<br />Zeichng.</th>
            <th>Stand</th>
            <th>Gewicht</th>
            <th>Leck-<br />Tester</th>
            <th>Dichtheit<br />(Werkst.)</th>
            <th className={styles.name_unterschrift_header}>Name<br />(Unterschrift)</th>
          </tr>
          {new Array(23).fill(0).map((_, i) => (
            <tr key={i} className={styles.empty_row}>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          ))

          }
        </tbody>
      </HTMLTable>
    </div>
  );
}

import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  LOCALE_DE,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core'; import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON, BegleitdokumentDaten } from 'wacoplast_wws__api';
import styles from './BegleitdokumentPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function BegleitdokumentPrint(props: PropsWithServices<{ data?: BegleitdokumentDaten, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Begleitdokument data={props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return BegleitdokumentCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function BegleitdokumentCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  return <Begleitdokument data={payload.begleitdokument!} />;
}

function Begleitdokument(props: { data: BegleitdokumentDaten, hidePrintButton?: boolean }): JSX.Element {
  const begleitdokument = props.data;

  return (
    <div className={styles.begleitdokument_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <HTMLTable className={styles.begleitdokument_table}>
        <thead>
          <tr>
            <th colSpan={3}>Begleit-Dokument</th>
            <th className={styles.header_date}>{formatDateString(new Date())} {new Date().toLocaleTimeString(LOCALE_DE, { timeStyle: 'short' })}</th>
          </tr>
          <tr />
          <tr>
            <th />
            <th />
            <th className={styles.right}>PAU-Nr.:</th>
            <th>{begleitdokument.produktionsauftrag_nummer}</th>
          </tr>
          <tr />
        </thead>
        <tbody>
          <tr>
            <th colSpan={2}>KUNDE:</th>
            <td colSpan={2}>{begleitdokument.kunde_name}</td>
          </tr>
          <tr>
            <th colSpan={2} className={styles.right}>Empfänger:</th>
            <td colSpan={2}>{begleitdokument.empfaenger_name}</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2} />
          </tr>
          <tr>
            <th colSpan={2}>LAGERPLATZ:</th>
            <td colSpan={2} />
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2} />
          </tr>
          <tr>
            <th colSpan={2}>LIEFERTERMIN:</th>
            <td colSpan={2}>{begleitdokument.liefertermin ? formatDateString(begleitdokument.liefertermin) : ''}</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2} />
          </tr>
          <tr>
            <th colSpan={2}>FL-TYP:</th>
            <td colSpan={2}>{begleitdokument.flaschen_typ_bezeichnung}</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2}>{begleitdokument.mit_blindenzeichen ? 'm.BLZ' : 'o.BLZ'}</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2}>{begleitdokument.farbe_bezeichnung}</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2}>{formatNumber(begleitdokument.artikel_gewicht, 1)} g</td>
          </tr>
          <tr>
            <th colSpan={2} />
            <td colSpan={2} />
          </tr>
          <tr>
            <th colSpan={2}>FL-TYP-Nr.:</th>
            <td colSpan={2}>{begleitdokument.flaschen_typ_nummer}</td>
          </tr>
          <tr>
            <th colSpan={2}>Druck:</th>
            <td colSpan={2} rowSpan={3} className={styles.druck_content} />
          </tr>
          <tr />
          <tr />
          <tr>
            <th colSpan={2} />
            <td colSpan={2} />
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={4}>Datum: ..............</th>
          </tr>
        </tfoot>
      </HTMLTable>
    </div>
  );

}

import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  LOCALE_DE,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON, MaschinenlaufplanDaten } from 'wacoplast_wws__api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import styles from './MLPPrint.module.scss';
import { formatNumber } from '../../../infrastructure/formatNumber';
import { MaterialGruppeKeyMapping } from '../../../stammdaten';


export function MaschinenlaufplanPrint(props: PropsWithServices<{ data?: MaschinenlaufplanDaten, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Maschinenlaufplan data={props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return MaschinenlaufplanCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function MaschinenlaufplanCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const mlp = payload.maschinenlaufplan;

  return <Maschinenlaufplan data={mlp} />;
}

function Maschinenlaufplan(props: { data: MaschinenlaufplanDaten, hidePrintButton?: boolean }): JSX.Element {
  const mlp = props.data;

  return (
    <div className={styles.mlpprint_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <span className={styles.mlp_title}>
        <span>Maschinenlaufplan</span>
        <div className={styles.mlp_title_datetime}>
          <span>{formatDateString(new Date())}</span>
          <span>{new Date().toLocaleTimeString(LOCALE_DE, { timeStyle: 'short' })}</span>
        </div>
      </span>
      <HTMLTable className={styles.mlp_table}>
        <tr className={styles.mlp_table_header}>
          <td />
          <th colSpan={2} className={styles.vorgaben_header}>{'>> Vorgaben <<'}</th>
          <th colSpan={2} className={styles.blasmaschine_nr_header}>Blasmaschine-Nr.:</th>
          <td className={styles.blasmaschine_nr}>{mlp.blasmaschine_nummer}</td>
        </tr>
        <tr className={styles.mlp_table_header}>
          <th className={styles.pau_nr_header}>PAU - NR:</th>
          <td className={styles.pau_nr}>{mlp.produktionsauftrag_nummer}</td>
          <td />
          <th colSpan={2} className={styles.stretcher_nr_header}>Stretcher-Nr.:</th>
          <td className={styles.stretcher_nr}>{mlp.kantenschutz ? '1, ' : ''}2, 3</td>
        </tr>
        <tr className={styles.spacer_row} />
        <tr className={styles.abschnitt}>
          <th>Bestelldatum:</th>
          <td colSpan={2} className={styles.border_bottom}>{formatDateString(mlp.bestelldatum)}</td>
          <th className={styles.liefertermin_header}>Liefertermin:</th>
          <td colSpan={2} className={styles.liefertermin}>{mlp.liefertermin ? formatDateString(mlp.liefertermin) : ''}</td>
        </tr>
        <tr>
          <th className={styles.border_bottom}>Gewicht [g]:</th>
          <td className={styles.border_bottom}>{Intl.NumberFormat(LOCALE_DE, { minimumFractionDigits: 1 }).format(mlp.artikel_gewicht)}</td>
          <td className={styles.border_bottom}>&#177; {Intl.NumberFormat(LOCALE_DE, { minimumFractionDigits: 1 }).format(mlp.gewichtstoleranz ?? 0)}</td>
          <th>Stückzahl:</th>
          <td colSpan={2} className={styles.liefertermin}>{formatNumber(mlp.menge_produktionsauftrag)}</td>
        </tr>
        <tr>
          <th className={styles.fl_typ_header}>FL-Typ:</th>
          <td className={styles.fl_typ} colSpan={2}>{mlp.flaschentyp_bezeichnung !== mlp.flaschentyp_nummer ? mlp.flaschentyp_nummer : ''}{mlp.flaschentyp_bezeichnung !== mlp.flaschentyp_nummer ? <br /> : ''}{mlp.flaschentyp_bezeichnung}</td>
          <th className={styles.farbe_bez_header}>Farbe:</th>
          <td className={styles.farbe_bez} colSpan={2}>{mlp.farbe_bezeichnung}</td>
        </tr>
        <tr>
          <th>Typ-Spez:</th>
          <td className={styles.border_bottom}>{mlp.blindenzeichen_bezeichnung}</td>
          <td className={styles.typ_spez} />
          <th>Platz-/Farb-/Batch-Nr.</th>
          <td colSpan={2} className={styles.liefertermin}>/ {mlp.farbe_nummer} / {mlp.farbe_batch_nummer}</td>
        </tr>
        <tr>
          <th className={styles.volumen_ht_header}>Vol. [ml] | HT:</th>
          <td className={styles.volumen}>{mlp.volumen}</td>
          <td className={styles.typ_spez}>{mlp.halsteil_nummer}<br />{mlp.halsteil_bezeichnung}</td>
          <th>Einfärbung [%]</th>
          <td className={styles.einfaerbung}>{mlp.einfaerbungsgrad ? Intl.NumberFormat(LOCALE_DE, { minimumFractionDigits: 1 }).format(mlp.einfaerbungsgrad) : ''}</td>
          <td className={styles.border_bottom} />
        </tr>
        <tr>
          <td className={styles.bemerkung}>zu Halsteil (HT):</td>
          <td colSpan={2} className={styles.bemerkung}>{mlp.halsteil_verwendung} / {mlp.halsteil_bemerkung}</td>
          <th>Material:</th>
          <td colSpan={2} className={styles.material}>{mlp.werkstoff_bezeichnung}</td>
        </tr>
        <tr>
          <th>KU-Artikel-Nr.:</th>
          <td colSpan={2} className={styles.border_bottom}>{mlp.artikel_nummer_kunde}</td>
          <th className={styles.werkstoff_typ_bezeichnung_header}> - Material-TYP:</th>
          <td colSpan={2} className={styles.werkstoff_typ_bezeichnung}>{!mlp.werkstoff_typ_2_bezeichnung ? mlp.werkstoff_typ_1_bezeichnung : `1: ${mlp.werkstoff_typ_1_bezeichnung}; 2: ${mlp.werkstoff_typ_2_bezeichnung}`}</td>
        </tr>
        <tr>
          <th>Bestell-Nr.:</th>
          <td colSpan={2} className={styles.border_bottom}>{mlp.kunde_bestell_nummer}</td>
          <th>Stück / Schicht:</th>
          <td colSpan={2} className={styles.liefertermin} />
        </tr>
        <tr>
          <th>Lagerort.:</th>
          <td colSpan={2} className={styles.border_bottom}>{mlp.lagerplatz_nummer}</td>
          <th>Taktzeit [sec]:</th>
          <td colSpan={2} className={styles.liefertermin} />
        </tr>
        <tr className={styles.spacer_row} />
        <tr className={styles.abschnitt} />
        <tr className={styles.grid}>
          <th>Euro-Palette</th>
          <td colSpan={2}>{mlp.transport_einheit_traeger_bezeichnung}</td>
          <td colSpan={3} rowSpan={2} className={styles.verpackungs_einheit_bezeichnung}>{mlp.verpackungs_einheit_bezeichnung}</td>
        </tr>
        <tr className={styles.grid}>
          <th>Unterlage</th>
          <td colSpan={2}>{mlp.unterlage_bezeichnung} {mlp.unterlage_anzahl ? `(${mlp.unterlage_anzahl})` : null}</td>
        </tr>
        <tr className={styles.grid}>
          <th>Karton-Sorte</th>
          <td colSpan={2}>{mlp.karton_sorte ? MaterialGruppeKeyMapping[mlp.karton_sorte] : ''}</td>
          <th rowSpan={2} className={styles.flaschen_pro_vpe_header}>Stück je {mlp.karton_geschlossen !== undefined ? 'Karton' : 'Tray'}:</th>
          <td colSpan={2} rowSpan={2} className={styles.liefertermin}>{mlp.flaschen_pro_vpe}{mlp.fuellweise_ist_schuetten ? ' (schütten)' : mlp.fuellweise_ist_schuetten === false ? ' (stellen)' : ''}</td>
        </tr>
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2} className={styles.border_bottom}>Karton</th>
          <th>mit Druck</th>
          <th>ohne Druck</th>
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.karton_aufdruck_art === 'mit' ? 'X' : '--'}</td>
          <td>{mlp.karton_aufdruck_art === 'ohne' ? 'X' : '--'}</td>
          <th rowSpan={2} className={styles.flaschen_pro_vpe_header}>{mlp.karton_geschlossen !== undefined ? 'Kartons' : 'Trays'} pro Stapel:</th>
          <td colSpan={2} rowSpan={2} className={styles.liefertermin}>{mlp.vpes_pro_stapel_1}{mlp.vpes_pro_stapel_2 ? ` / ${mlp.vpes_pro_stapel_2}` : ''}</td>
        </tr>
        <tr className={styles.grid_bunt_header}>
          <th>Karton-Qualität</th>
          <th>neu</th>
          <th>alt</th>
        </tr>
        <tr className={styles.grid_bunt}>
          <th className={styles.karton_vp_header}>Karton-VP: </th>
          <td>{mlp.karton_aufdruck_art && mlp.vpe_zustand === 'neu' ? 'X' : '--'}</td>
          <td>{mlp.karton_aufdruck_art && mlp.vpe_zustand === 'alt' ? 'X' : '--'}</td>
          <th className={styles.border_bottom}>Anzahl Stapel</th>
          <td colSpan={2} className={styles.liefertermin}>{mlp.anzahl_stapel_1}{mlp.anzahl_stapel_2 ? ` / ${mlp.anzahl_stapel_2}` : ''}</td>
        </tr>
        <tr className={styles.centered_text_row_data}>
          <th className={styles.karton_vp_header}>Tray-VP:</th>
          <td className={styles.tray_vp_header}>{!mlp.karton_aufdruck_art && mlp.vpe_zustand === 'neu' ? 'X' : ''}</td>
          <td className={styles.tray_vp_header}>{!mlp.karton_aufdruck_art && mlp.vpe_zustand === 'alt' ? 'X' : ''}</td>
          <th rowSpan={3}>Menge {mlp.karton_geschlossen !== undefined ? 'Kartons' : 'Trays'}:</th>
          {mlp.karton_geschlossen === undefined && (
            <td rowSpan={3}>1/2-T: {mlp.menge_halbtrays}</td>
          )}
          {mlp.karton_geschlossen !== undefined && (
            <td rowSpan={3}>Kartons: {mlp.vpes_pro_stapel_1 * mlp.anzahl_stapel_1 + (mlp.anzahl_stapel_2 && mlp.vpes_pro_stapel_2 ? (mlp.vpes_pro_stapel_2 * mlp.anzahl_stapel_2) : 0)}</td>
          )}
          <td rowSpan={3}>1/1-T: {mlp.menge_ganztrays}</td>
        </tr>
        <tr className={styles.centered_text_row_data}>
          <th className={styles.zw_lage_deckel_header}> - Zw-Lage</th>
          <td className={styles.zw_lage}>{mlp.zwischenlage_nummer === '52' ? 'X' : '--'}</td>
          <td className={styles.zw_lage}>{mlp.zwischenlage_nummer === '55' ? 'X' : '--'}</td>
        </tr>
        <tr className={styles.centered_text_row_data}>
          <th className={styles.zw_lage_deckel_header}> - Deckel</th>
          <td>{mlp.abdeckung_nummer === '52' ? 'X' : '--'}</td>
          <td>{mlp.abdeckung_nummer === '55' ? 'X' : '--'}</td>
        </tr>
        <tr className={styles.abschnitt} />
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2}>Karton</th>
          <th>offen</th>
          <th>zu</th>
          <th>Kunde:</th>
          <td colSpan={2} className={styles.kunde_name}>{mlp.kunde_name}</td>
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.karton_geschlossen === undefined ? '--' : !mlp.karton_geschlossen ? 'X' : null}</td>
          <td>{mlp.karton_geschlossen === undefined ? '--' : mlp.karton_geschlossen ? 'X' : null}</td>
          <th className={styles.empfaenger_header}>Empfänger:</th>
          <td colSpan={2} className={styles.kunde_name}>{mlp.empfaenger_name}</td>
        </tr>
        <tr className={styles.abschnitt} />
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2}>Etikett</th>
          <th>ja</th>
          <td />
          <th colSpan={3} className={styles.bemerkung_header}>Bemerkung:</th>
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.mit_etikett === undefined ? '--' : mlp.mit_etikett ? 'X' : null}</td>
          <td />
          <td colSpan={3} rowSpan={5} className={styles.bemerkung_text}><p>{mlp.bemerkung.split('\n').map((line, index, all) => <>{line}{index === all.length - 1 ? '' : <br />}</>)}</p></td>
        </tr>
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2}>Polybeutel</th>
          <th>ja</th>
          <th>nein</th>
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.karton_polybeutel === undefined ? '--' : mlp.karton_polybeutel ? 'X' : null}</td>
          <td>{mlp.karton_polybeutel === undefined ? '--' : !mlp.karton_polybeutel ? 'X' : null}</td>
        </tr>
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2}>Stretchen</th>
          <th>ja</th>
          <th>nein</th>
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.stretchen === undefined ? '--' : mlp.stretchen ? 'X' : null}</td>
          <td>{mlp.stretchen === undefined ? '--' : !mlp.stretchen ? 'X' : null}</td>
        </tr>
        <tr className={styles.abschnitt} />
        <tr className={styles.grid_bunt_header}>
          <th rowSpan={2}>Kantenschutz</th>
          <th>ja</th>
          <th>nein</th>
          <td colSpan={3} className={styles.empty_gray_triple_cell} />
        </tr>
        <tr className={styles.grid_bunt}>
          <td>{mlp.kantenschutz === undefined ? '--' : mlp.kantenschutz ? 'X' : null}</td>
          <td>{mlp.kantenschutz === undefined ? '--' : !mlp.kantenschutz ? 'X' : null}</td>
          <th className={styles.border_right}>Mündungszeichn. Nr.:</th>
          <td colSpan={2} />
        </tr>
        <tr className={styles.abschnitt} />
        <tr>
          <th>Prüfverschluß:</th>
          <td colSpan={2} />
          <th className={styles.border_right}>Palettenhöhe [mm]:</th>
          <td className={styles.palettenhoehe}>{formatNumber(mlp.palettenhoehe_1)}{mlp.palettenhoehe_2 ? ` / ${formatNumber(mlp.palettenhoehe_2)}` : ''}</td>
          <td />
        </tr>
      </HTMLTable>
    </div>
  );
}

import {
  Button,
  Dialog,
  H2,
  H5,
} from '@blueprintjs/core';
import {
  AsyncButton,
  CustomFormProps,
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
  formatDateTimeString,
} from '../../../infrastructure';

import { ArbeitspapiereDatenLadenResult } from 'wacoplast_wws__api';
import styles from './ArbeitspapiereAnzeigen.module.scss';
import { useState } from 'react';

export function ArbeitspapiereAnzeigen(props: PropsWithServices<CustomFormProps<ArbeitspapiereDatenLadenResult>> & ModalProps<void>): JSX.Element {
  const [pdfBlob, setPdfBlob] = useState<null | string>(null);

  if (pdfBlob) {
    return (
      <Dialog title={'Arbeitspapiere'} isOpen={true} onClose={() => setPdfBlob(null)}>
        <DialogBody>
          <embed src={pdfBlob} type='application/pdf' width={'100%'} height={'100%'} style={{ minHeight: '70vh', minWidth: '65vw' }} />
          Kontrollieren Sie die Arbeitspapiere und drucken Sie diese über das Symbol am oberen Rand des Dokuments.
        </DialogBody>
        <DialogFooter onFinish={() => setPdfBlob(null)} />
      </Dialog>
    );
  }

  const finish = async (): Promise<void> => {
    await props.finishUserTask({});
    props.onModalFinished();
  };

  return (
    <Dialog
      isOpen
      onClose={() => props.onModalFinished()}
      title='Papiere drucken'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <H2>Die Arbeitspapiere können nun gedruckt werden.</H2>
        {props.userTask.startedAt && <H5>Generiert am: {formatDateTimeString(props.userTask.startedAt)}</H5>}
        <div className={styles.arbeitspapiere_buttons_grid}>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/maschinenlaufplan/${props.userTask.correlationId}`, '_blank')}>MLP öffnen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/stellblatt/${props.userTask.correlationId}`, '_blank')}>Stellblatt öffnen</Button>
          {props.tokenPayload.qhinweise.hinweise.length > 0 &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/qhinweise/${props.userTask.correlationId}`, '_blank')}>Q-Hinweise öffnen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/pruefprotokoll/${props.userTask.correlationId}`, '_blank')}>Prüfprotokoll öffnen</Button>
          {props.tokenPayload.packordnung &&
            <Button intent='none' onClick={() => window.open(`${props.config.restServiceUrl}/file_storage/${props.tokenPayload.packordnung}`, '_blank')}>Packordnung öffnen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/gewichtskontrolle/${props.userTask.correlationId}`, '_blank')}>Gewichtskontrolle öffnen</Button>
          {props.tokenPayload.begleitdokument &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/begleitdokument/${props.userTask.correlationId}`, '_blank')}>Begleitdokument öffnen</Button>
          }
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/vp_unterschriften/${props.userTask.correlationId}`, '_blank')}>VP-Unterschriften öffnen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/rueckmeldung1/${props.userTask.correlationId}`, '_blank')}>Rückmeldung1 öffnen</Button>
          <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/rueckmeldung2/${props.userTask.correlationId}`, '_blank')}>Rückmeldung2 öffnen</Button>
          {props.tokenPayload.etikett_tray &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/etikett_tray/${props.userTask.correlationId}`, '_blank')}>Etikett-Tray öffnen</Button>
          }
          {props.tokenPayload.etikett_karton &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/etikett_karton/${props.userTask.correlationId}`, '_blank')}>Etikett-Karton öffnen</Button>
          }
          {props.tokenPayload.abmusterung &&
            <Button intent='none' onClick={() => window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/abmusterung/${props.userTask.correlationId}`, '_blank')}>Abmusterung öffnen</Button>
          }
        </div>
      </DialogBody>
      <DialogFooter
        onFinish={finish}
        onAbort={props.onModalFinished}
        disabled={props.disabled}
      >
        <AsyncButton onClick={() => {
          window.open(`${props.config.redirectBasePath}/print/arbeitspapiere/all/${props.userTask.correlationId}`, '_blank');
          window?.addEventListener('message', (event: any) => {
            if (event.data.type === 'pdfAsBlob') {
              setPdfBlob(event.data.data);
            }
          });
        }}
        >Alle drucken
        </AsyncButton>
      </DialogFooter>
    </Dialog>
  );
}

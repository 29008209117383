/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Dialog, Tag } from '@blueprintjs/core';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';
import { FileMetadata, HalsTeilFileStorageAssociation } from 'wacoplast_wws__api';
import { AsyncButton, DialogBody, DialogFooter } from '../../infrastructure';

export function FileDownloadCellRenderer<TRowType extends { specification_files: Array<HalsTeilFileStorageAssociation> }>(props: Omit<ICellRendererParams, 'data'> & { data: TRowType, restServiceUrl: string }): JSX.Element {
  const [fileMetadata, setFileMetadata] = useState<FileMetadata | null>(null);
  const url = `${props.restServiceUrl}/file_storage/${fileMetadata?.hash}#view=FitH`;
  const anchorRef = React.createRef<HTMLAnchorElement>();
  return (
    <div>
      <Dialog title={fileMetadata?.file_name} isOpen={fileMetadata !== null} onClose={() => setFileMetadata(null)}>
        <DialogBody>
          <embed src={url} type='application/pdf' width={'100%'} height={'100%'} style={{ minHeight: '70vh', minWidth: '65vw' }} />
        </DialogBody>
        <DialogFooter onFinish={() => setFileMetadata(null)}>
          <a target='_blank' rel='noreferrer' download={fileMetadata?.file_name} ref={anchorRef} href={url} style={{ display: 'none' }} />
          <AsyncButton onClick={() => anchorRef.current?.click()}>Öffnen</AsyncButton>
        </DialogFooter>
      </Dialog>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {
          props.data.specification_files.map((fileAssociation, index) => {
            return (
              <div key={index}>
                <Tag interactive
                  minimal
                  icon={<FontAwesomeIcon icon={faFileDownload} size='1x' />}
                  onClick={async () => {
                    setFileMetadata(fileAssociation.specification_file);
                  }}
                />
                {fileAssociation.specification_file.file_name}
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

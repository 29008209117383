import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import {
  ArbeitspapiereDatenLadenResult,
  ArbeitspapiereDatenLadenResultFromJSON,
  MaschinenlaufplanDaten,
  StellblattDaten,
} from 'wacoplast_wws__api';
import styles from './StellblattPrint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts';
import abstaende from './assets/abstaende.png';
import blasdornspitze from './assets/blasdornspitze.png';
import abschlagbacken from './assets/abschlagbacken.png';
import logo from './assets/logo.png';

export function StellblattPrint(props: PropsWithServices<{ data?: { maschinenlaufplan: MaschinenlaufplanDaten, stellblatt: StellblattDaten }, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Stellblatt {...props.data} hidePrintButton />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return StellblattCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

const thirds = new Array(31).fill(0).map((_, index) => index / 3);

function WkbGraph(props: any): JSX.Element {
  const data = new Array(30).fill(0).map((_, index) => {
    const value = props.stellblatt[`wbk_${index}` as keyof StellblattDaten] as number;
    return {
      position: index,
      wbk: value,
    };
  });

  return (
    <LineChart
      margin={{ top: 0, right: 10, left: -20, bottom: 20 }}
      width={666}
      height={333}
      data={data}
    >
      <CartesianGrid
        horizontal={false}
        stroke='red'
      />
      {thirds.map((third) => (<ReferenceLine key={third} y={-third} stroke={third % 1 === 0 ? 'black' : '#bd9709'} isFront={false} />))}
      <XAxis orientation='top' dataKey='position' interval={0} strokeWidth={3} tick={{ stroke: '#333', strokeWidth: 1 }} />
      <YAxis dataKey='wbk' scale='linear' ticks={[-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, -0]} strokeWidth={3} tick={{ stroke: '#333', strokeWidth: 1 }} />
      <Line stroke='#d45351' dot={<></>} animationDuration={0} strokeWidth={5} dataKey='wbk' />
    </LineChart>
  );
}

function StellblattCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);
  const { maschinenlaufplan, stellblatt } = payload;

  return <Stellblatt maschinenlaufplan={maschinenlaufplan} stellblatt={stellblatt} />;
}

function Stellblatt(props: { maschinenlaufplan: MaschinenlaufplanDaten, stellblatt: StellblattDaten, hidePrintButton?: boolean }): JSX.Element {
  const { maschinenlaufplan, stellblatt } = props;

  const spacer_cells = [];
  for (let i = 0; i < 39; i++) {
    spacer_cells.push(<td key={i} className={styles.spacer_cells} />);
  }

  return (
    <div className={styles.stellblatt_page_container}>
      {!props.hidePrintButton &&
        <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      }
      <span className={styles.stellblatt_title}>
        <span>CL-303</span>
        <span className={styles.stellblatt_title_word}>Stellblatt</span>
        <div className={styles.logo}>
          <img src={logo} alt='' />
        </div>
      </span>
      <HTMLTable className={styles.stellblatt_table}>
        <tbody>
          <tr>
            <th colSpan={2} className={styles.gray_bg_large}>BLM</th>
            <td colSpan={2}>{stellblatt.blasmaschine_nummer}</td>
            <th colSpan={5} className={styles.gray_bg_large}>FL-Typ-Nr.</th>
            <td colSpan={8}>{maschinenlaufplan.flaschentyp_nummer}</td>
            <th colSpan={4} className={styles.gray_bg_center}>HT-Nrn./Lpl</th>
            <td colSpan={3} className={styles.dashed_border_right}>{stellblatt.halsteil_nummer}</td>
            <td colSpan={3} />
            <th colSpan={2} className={styles.gray_bg_large}>WS</th>
            <td colSpan={2}>{stellblatt.werkstoff_nummer}</td>
            <td colSpan={8}>{maschinenlaufplan.werkstoff_bezeichnung}</td>
          </tr>
          <tr>
            <th colSpan={2} className={styles.gray_bg_large}>PAU</th>
            <td colSpan={4}>{stellblatt.produktionsauftrag_nummer}</td>
            <th colSpan={5} className={styles.gray_bg_large}>FL-Typ-Bez.</th>
            <td colSpan={9}>{maschinenlaufplan.flaschentyp_bezeichnung}</td>
            <th colSpan={3} className={styles.gray_bg_large}>HT-Bez.</th>
            <td colSpan={7}>{stellblatt.halsteil_bezeichnung}</td>
            <th colSpan={2} className={styles.gray_bg_large}>G [g]</th>
            <td colSpan={7}>{stellblatt.artikel_gewicht} (-/{stellblatt.datengruppe_3_gewicht ?? '-'}/{stellblatt.blasform_ep_gewicht ?? '-'})</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.gray_bg_large}>Blasform</th>
            <th colSpan={4} className={styles.gray_bg_small}>lfd. BLF-Nr.</th>
            <td colSpan={3}>{stellblatt.blasform_nummer}</td>
            <th colSpan={3} className={styles.gray_bg_small}>BLF-Nr.</th>
            <td colSpan={3}>{stellblatt.blasform_nummer_intern}</td>
            <th colSpan={12} className={styles.gray_bg_large} />
            <th colSpan={2} className={styles.gray_bg_large}>BLZ</th>
            <td>{stellblatt.blindenzeichen_nummer_1}</td>
            <td>{stellblatt.blindenzeichen_nummer_2}</td>
            <td>{stellblatt.blindenzeichen_nummer_3}</td>
            <td>{stellblatt.blindenzeichen_nummer_4}</td>
            <td>{stellblatt.blindenzeichen_nummer_5}</td>
          </tr>
          <tr>
            <th colSpan={4} className={styles.gray_bg_small}>Lagerplatz</th>
            <td colSpan={3}>{stellblatt.lagerplatz_nummer}</td>
            <th colSpan={3} className={styles.gray_bg_small} />
            <td colSpan={3} />
            <th colSpan={2} className={styles.gray_bg_small}>Stich</th>
            <td colSpan={2}>{stellblatt.stich}</td>
            <th colSpan={3} className={styles.gray_bg_small}>Anz. Kav.</th>
            <td>{stellblatt.anzahl_kavitaeten}</td>
            <th colSpan={3} className={styles.gray_bg_small}>Nester</th>
            <td>{stellblatt.nest_nummer_1 !== 0 ? stellblatt.nest_nummer_1 : ''}</td>
            <td>{stellblatt.nest_nummer_2 !== 0 ? stellblatt.nest_nummer_2 : ''}</td>
            <td>{stellblatt.nest_nummer_3 !== 0 ? stellblatt.nest_nummer_3 : ''}</td>
            <td>{stellblatt.nest_nummer_4 !== 0 ? stellblatt.nest_nummer_4 : ''}</td>
            <td>{stellblatt.nest_nummer_5 !== 0 ? stellblatt.nest_nummer_5 : ''}</td>
            <td>{stellblatt.nest_nummer_6 !== 0 ? stellblatt.nest_nummer_6 : ''}</td>
            <td>{stellblatt.nest_nummer_7 !== 0 ? stellblatt.nest_nummer_7 : ''}</td>
            <td>{stellblatt.nest_nummer_8 !== 0 ? stellblatt.nest_nummer_8 : ''}</td>
            <td>{stellblatt.nest_nummer_9 !== 0 ? stellblatt.nest_nummer_9 : ''}</td>
            <td>{stellblatt.nest_nummer_10 !== 0 ? stellblatt.nest_nummer_10 : ''}</td>
            <th colSpan={3} className={styles.gray_bg_small}>W-BLZ</th>
            <td colSpan={2}>{stellblatt.wechselbares_blindenzeichen ? 'ja' : 'nein'}</td>
          </tr>
          <tr>
            <th colSpan={39} className={styles.gray_bg}>Hinweise zu Blasform, Werkzeug und Maschine</th>
          </tr>
          <tr className={styles.hinweise}>
            <td colSpan={39}>{stellblatt.blasform_hinweis}</td>
          </tr>
          <tr>
            <th colSpan={6} className={styles.gray_bg_dashed_border}>Düse</th>
            <th colSpan={2} className={styles.pink_header_bold_small}>Bez.</th>
            <td colSpan={6}>{stellblatt.duese_bezeichnung}</td>
            <th colSpan={3} className={styles.pink_header_bold_small}>Ø [mm]</th>
            <td colSpan={3}>{stellblatt.duese_durchmesser}</td>
            <th colSpan={4} className={styles.pink_header_bold_small}>Profil [mm]</th>
            <td colSpan={3}>{stellblatt.duese_profil}</td>
            <th colSpan={6} className={styles.gray_bg_dashed_border}>Schneidring</th>
            <th colSpan={3} className={styles.pink_header_bold_small}>Ø [mm]</th>
            <td colSpan={3}>{stellblatt.schneidring_durchmesser}</td>
          </tr>
          <tr>
            <th colSpan={6} className={styles.gray_bg_dashed_border}>Blasdornspitze</th>
            <th colSpan={3} className={styles.pink_header_bold_small}>L1 [mm]</th>
            <td colSpan={3}>{stellblatt.blasdornsp_l1}</td>
            <th colSpan={12} className={styles.gray_bg_dashed_border}>Abschlagbacken</th>
            <th colSpan={15} className={styles.abstaende}>Abstände</th>
          </tr>
          <tr>
            <th colSpan={6} rowSpan={5} className={styles.gray_bg}>
              <img src={blasdornspitze} alt='' className={styles.blasdornspitze_image} />
            </th>
            <th colSpan={3} className={styles.pink_header_bold_small}>L2 [mm]</th>
            <td colSpan={3}>{stellblatt.blasdornsp_l2}</td>
            <th colSpan={6} rowSpan={6} className={styles.gray_bg}>
              <img src={abschlagbacken} alt='' className={styles.abschlagbacken_image} />
            </th>
            <th colSpan={4} className={styles.pink_header_bold_small}>U-Blech [mm]</th>
            <td colSpan={2}>{stellblatt.unterlegbleche}</td>
            <th colSpan={2} className={styles.kopf_messer_form}>Kopf</th>
            <th colSpan={8} rowSpan={6} className={styles.kopf_messer_form}>
              <img src={abstaende} alt='' className={styles.abstaende_image} />
            </th>
          </tr>
          <tr>
            <th colSpan={3} className={styles.pink_header_bold_small}>L3 [mm]</th>
            <td colSpan={3}>{stellblatt.blasdornsp_l3}</td>
            <th colSpan={3} className={styles.pink_header_bold_small}>konisch</th>
            <td colSpan={3}>{stellblatt.abschlagbacken_konisch}</td>
            <th className={styles.kopf_messer_form} />
            <th className={styles.gray_bg_border_left} />
            <th colSpan={3} className={styles.pink_header_bold_small}>a [mm]</th>
            <td colSpan={3}>{stellblatt.stellblatt_abstaende_a}</td>
          </tr>
          <tr>
            <th colSpan={3} className={styles.pink_header_bold_small}>D1 [mm]</th>
            <td colSpan={3}>{stellblatt.blasdornsp_d1}</td>
            <th colSpan={3} className={styles.pink_header_bold_small}>H1 [mm]</th>
            <td colSpan={3}>{stellblatt.backenhoehe_h1}</td>
            <th colSpan={2} className={styles.kopf_messer_form}>Messer</th>
          </tr>
          <tr>
            <th colSpan={3} className={styles.pink_header_bold_small}>D2 [mm]</th>
            <td colSpan={3}>{stellblatt.blasdornsp_d2}</td>
            <th colSpan={3} className={styles.pink_header_bold_small}>H2 [mm]</th>
            <td colSpan={3}>{stellblatt.steghoehe_h2}</td>
            <th className={styles.kopf_messer_form} />
            <th className={styles.gray_bg_border_left} />
            <th colSpan={3} className={styles.pink_header_bold_small}>b [mm]</th>
            <td colSpan={3}>{stellblatt.stellblatt_abstaende_b}</td>
          </tr>
          <tr>
            <th colSpan={3} className={styles.pink_header_bold_small}>Radius</th>
            <td colSpan={3}>{stellblatt.blasdornsp_radius}</td>
            <th colSpan={3} className={styles.pink_header_bold_small}>D1 [mm]</th>
            <td colSpan={3}>{stellblatt.abschlagbacken_d1}</td>
            <th colSpan={2} className={styles.kopf_messer_form}>Form</th>
          </tr>
          <tr>
            <th colSpan={12} className={styles.gray_bg} />
            <th colSpan={3} className={styles.pink_header_bold_small}>D2 [mm]</th>
            <td colSpan={3}>{stellblatt.abschlagbacken_d2}</td>
            <th colSpan={2} className={styles.kopf_messer_form} />
          </tr>
          <tr>
            <th colSpan={39} className={styles.gray_bg}>Zeitensollwerte [sec]</th>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Stück / h</th>
            <td colSpan={3}>{stellblatt.stueck_pro_stunde}</td>
            <th colSpan={7} className={styles.pink_header_left}>Blasdruck [bar]</th>
            <td colSpan={3}>{stellblatt.blasdruck}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Stanze vor</th>
            <td colSpan={3}>{stellblatt.verz_stanze_vor}</td>
            <th colSpan={6} className={styles.pink_header_left}>Zeit Bandstop</th>
            <td colSpan={3}>{stellblatt.zeit_bandstop}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Masch.-Taktgeb.</th>
            <td colSpan={3}>{stellblatt.maschinen_taktgeber}</td>
            <th colSpan={7} className={styles.pink_header_left}>Blaszeit (ist)</th>
            <td colSpan={3}>{stellblatt.blaszeit}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Stanze zur.</th>
            <td colSpan={3}>{stellblatt.verz_stanze_zur}</td>
            <th colSpan={6} className={styles.pink_header_left}>Verz. Stützluft</th>
            <td colSpan={3}>{stellblatt.verz_stuetzluft}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Masch.-Laufkontr.</th>
            <td colSpan={3}>{stellblatt.maschinen_laufkontrolle}</td>
            <th colSpan={7} className={styles.pink_header_left}>Standzeit</th>
            <td colSpan={3}>{stellblatt.standzeit}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Greifer zu</th>
            <td colSpan={3}>{stellblatt.verz_greifer_zu}</td>
            <th colSpan={6} className={styles.pink_header_left}>Dauer Stützluft</th>
            <td colSpan={3}>{stellblatt.dauer_stuetzluft}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Trennen vor</th>
            <td colSpan={3}>{stellblatt.verz_trennen_1_vor}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz.Form zu</th>
            <td colSpan={3}>{stellblatt.verz_form_zu}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Gr. auf (Se)</th>
            <td colSpan={3}>{stellblatt.verz_greifer_auf_se}</td>
            <th colSpan={6} className={styles.pink_header_left}>Verz.Dekomp.</th>
            <td colSpan={3}>{stellblatt.verz_dekomp}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Trennen zur</th>
            <td colSpan={3}>{stellblatt.verz_trennen_1_zur}</td>
            <th colSpan={7} className={styles.pink_header_left}>Lüftzeit Dorn</th>
            <td colSpan={3}>{stellblatt.lueftzeit_dorn}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Gr. auf (Le)</th>
            <td colSpan={3}>{stellblatt.verz_greifer_auf_le}</td>
            <th colSpan={6} className={styles.pink_header_left}>Dauer Dekomp</th>
            <td colSpan={3}>{stellblatt.dauer_dekomp}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Zeitgl. Trennen</th>
            <td colSpan={3}>{stellblatt.zeitglied_trennen_1}</td>
            <th colSpan={7} className={styles.pink_header_left}>Lüftzeit Form</th>
            <td colSpan={3}>{stellblatt.lueftzeit_form}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Entn. Senk.</th>
            <td colSpan={3}>{stellblatt.verz_ent_senk}</td>
            <th colSpan={6} className={styles.pink_header_left}>Verz. Pneum. 1</th>
            <td colSpan={3}>{stellblatt.verz_pneum_1}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Bl./Bld.</th>
            <td colSpan={3}>{stellblatt.verz_blas_blad}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Wagen ↓</th>
            <td colSpan={3}>{stellblatt.verz_wagen_ab}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Dorn hoch</th>
            <td colSpan={3}>{stellblatt.verz_dorn_hoch}</td>
            <th colSpan={6} className={styles.pink_header_left}>Dauer Pneu. 1</th>
            <td colSpan={3}>{stellblatt.dauer_pneum_1}</td>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_left}>Vorblaszeit</th>
            <td colSpan={3}>{stellblatt.vorblaszeit}</td>
            <th colSpan={7} className={styles.pink_header_left}>Verz. Wagen ↑</th>
            <td colSpan={3}>{stellblatt.verz_wagen_hoch}</td>
            <th colSpan={7} className={styles.pink_header_left}>Blaszeit (Richtwert)</th>
            <td colSpan={3}>{stellblatt.blaszeit}</td>
          </tr>
          <tr>
            <th colSpan={39} className={styles.gray_bg_center}>Extruder-Temperaturen</th>
          </tr>
          <tr>
            <th colSpan={7} className={styles.pink_header_bold}>Richtwert [°C]</th>
            <td colSpan={1} className={styles.no_border_y} />
            <th colSpan={7} className={styles.pink_header_bold_left}>Korrekturen</th>
          </tr>
          <tr>
            <td colSpan={7} rowSpan={2}>{stellblatt.extruder_temperatur_richtwert}</td>
            <td colSpan={1} className={styles.no_border_y} />
            <th colSpan={1} className={styles.pink_header_bold_small}>Z</th>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_1}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_2}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_3}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_4}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_5}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_6}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_7}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_8}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_9}</td>
            <td colSpan={3} className={styles.gray_bg}>{stellblatt.zone_10}</td>
          </tr>
          <tr>
            <td colSpan={1} className={styles.no_border_y} />
            <th colSpan={1} className={styles.center_small}>T</th>
            <td colSpan={3}>{stellblatt.zone_1_temp}</td>
            <td colSpan={3}>{stellblatt.zone_2_temp}</td>
            <td colSpan={3}>{stellblatt.zone_3_temp}</td>
            <td colSpan={3}>{stellblatt.zone_4_temp}</td>
            <td colSpan={3}>{stellblatt.zone_5_temp}</td>
            <td colSpan={3}>{stellblatt.zone_6_temp}</td>
            <td colSpan={3}>{stellblatt.zone_7_temp}</td>
            <td colSpan={3}>{stellblatt.zone_8_temp}</td>
            <td colSpan={3}>{stellblatt.zone_9_temp}</td>
            <td colSpan={3}>{stellblatt.zone_10_temp}</td>
          </tr>
          <tr>
            {spacer_cells}
          </tr>
          <tr>
            <th colSpan={9} className={styles.pink_header_bold}>Programm-Zeit [sec]</th>
            <td colSpan={3}>{stellblatt.programm_zeit}</td>
            <td colSpan={3} className={styles.no_border_y} />
            <th colSpan={9} className={styles.pink_header_bold}>Programm-Hub [mm]</th>
            <td colSpan={3}>{stellblatt.programm_hub}</td>
            <td colSpan={3} className={styles.no_border_y} />
            <th colSpan={6} className={styles.pink_header_bold}>Masch.-Zyklus</th>
            <td colSpan={3}>{stellblatt.maschinen_zyklus}</td>
          </tr>
          <tr>
            <td colSpan={39} style={{ maxHeight: '7.2cm' }}>
              <WkbGraph stellblatt={stellblatt} />
            </td>
          </tr>
          <tr>
            <th colSpan={39} className={styles.gray_bg}>Hinweise zur Produktion und Optimierung/QS</th>
          </tr>
          <tr className={styles.hinweise}>
            <td colSpan={39}>{stellblatt.produktionshinweis}</td>
          </tr>
          <tr>
            <th colSpan={14}>Erklärung zu Gewicht:  A (B/C/D)</th>
            <td colSpan={25} />
          </tr>
          <tr>
            <th colSpan={4}>A: aus PAU</th>
            <th colSpan={10}>B: aus Blaszeit-Richtwert</th>
            <th colSpan={3} className={styles.gray_bg_right_medium}>Datum</th>
            <td colSpan={5} />
            <th colSpan={10} className={styles.gray_bg_right_medium}>Unterschrift (Einrichter)</th>
            <td colSpan={7} />
          </tr>
          <tr>
            <th colSpan={4}>C: aus DG3</th>
            <th colSpan={10} className={styles.dashed_border}>D: von Blasform (EP)</th>
            <th colSpan={3} className={styles.gray_bg_right_medium}>Uhrzeit</th>
            <td colSpan={5} />
            <th colSpan={10} className={styles.gray_bg_right_medium}>Unterschrift (Schichtführer)</th>
            <td colSpan={7} />
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}

import { useParams } from 'react-router-dom';
import {
  CustomFormProps,
  DialogBody,
  PropsWithServices,
  RenderSequentialUserTasks,
  formatDateString,
} from '../../../infrastructure';
import { Identity } from '@atlas-engine/atlas_engine_client';
import {
  Button,
  Dialog,
  HTMLTable,
  Spinner,
} from '@blueprintjs/core';
import { AbmusterungDaten, ArbeitspapiereDatenLadenResult, ArbeitspapiereDatenLadenResultFromJSON } from 'wacoplast_wws__api';
import styles from './AbmusterungPrint.module.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatNumber } from '../../../infrastructure/formatNumber';

export function AbmusterungPrint(props: PropsWithServices<{ data?: AbmusterungDaten, getIdentity: () => Identity }>): JSX.Element {
  const { correlation_id } = useParams();

  if (props.data) {
    return <Abmusterung data={props.data} />;
  }

  return (
    <RenderSequentialUserTasks
      correlationId={correlation_id ?? ''}
      getIdentity={props.getIdentity}
      atlasEngineClient={props.atlasEngineClient}
      getComponent={(userTask) => {
        if (userTask.userTaskConfig.customForm === 'wacoplast-wws.ArbeitspapiereAnzeigen') {
          return AbmusterungCustomForm;
        }
        return null;
      }}
    >
      <Dialog
        isOpen={true}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        canEscapeKeyClose={false}
        title='Einen Moment bitte'
      >
        <DialogBody>
          <Spinner />
        </DialogBody>
      </Dialog>
    </RenderSequentialUserTasks>
  );
}

function AbmusterungCustomForm(props: CustomFormProps<ArbeitspapiereDatenLadenResult>): JSX.Element {
  const payload = ArbeitspapiereDatenLadenResultFromJSON(props.tokenPayload);

  if (!payload.abmusterung) {
    return <></>;
  }

  return <Abmusterung data={payload.abmusterung} />;
}

function Abmusterung(props: { data: AbmusterungDaten }): JSX.Element {
  const abmusterung = props.data;

  return (
    <div className={styles.abmusterung_page_container}>
      <Button className={styles.print_button} onClick={() => window.print()}><FontAwesomeIcon icon={faPrint} /> Drucken</Button>
      <HTMLTable className={styles.abmusterung_table}>
        <thead>
          <tr>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <th className={styles.fat_border_dark_bg}>verantwortlich</th>
            <td />
            <th colSpan={3} className={styles.checkliste_header}>Checkliste</th>
          </tr>
          <tr>
            <th className={styles.dotted_bottom_border_gray_bg}>AUFTRAG</th>
            <th className={styles.fat_border_dark_bg}>Auftragsabwicklung</th>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Form vorhanden und i.O.</td>
          </tr>
          <tr>
            <th className={styles.right}>Kunde:</th>
            <td className={styles.border}>{abmusterung?.kunde_name}</td>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Verschlüsse bekannt und vorhanden</td>
          </tr>
          <tr>
            <th className={styles.right}>Bestell-Datum:</th>
            <td className={styles.border}>{abmusterung ? formatDateString(abmusterung?.bestelldatum) : ''}</td>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Spezifikation / Zeichnung vorhanden</td>
          </tr>
          <tr>
            <th className={styles.right}>Abm. - Auftrags.Nr.:</th>
            <td className={styles.border}>{abmusterung?.produktionsauftrag_nummer}</td>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Farbmuster vorhanden</td>
          </tr>
          <tr>
            <th className={styles.right}>Artikel-Bez:</th>
            <td className={styles.border}>{abmusterung?.flaschen_typ_bezeichnung}</td>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Farbe vorhanden</td>
          </tr>
          <tr>
            <th className={styles.right}>Artikel-Nr.:</th>
            <td className={styles.border}>{abmusterung?.artikel_nummer_kunde}</td>
            <td className={styles.fat_border_right} />
            <td className={styles.border} />
            <td colSpan={2} className={styles.border}>Basismaterial festgelegt</td>
          </tr>
          <tr>
            <th className={styles.right}>Halsteil-Bez.:</th>
            <td className={styles.border}>{abmusterung?.halsteil_bezeichnung}</td>
            <td className={styles.border}>Bemerkung</td>
            <td colSpan={3} className={styles.fat_border_top} />
          </tr>
          <tr>
            <th className={styles.right}>Farbe:</th>
            <td className={styles.border}>{abmusterung?.farbe_bezeichnung}</td>
            <td className={styles.border}>Einfärbung [%]</td>
            <td colSpan={2} className={styles.border}>{formatNumber(abmusterung?.einfaerbungsgrad, 1)}</td>
            <td className={styles.border_top} />
          </tr>
          <tr>
            <th className={styles.right}>Batch-Nr.:</th>
            <td className={styles.border}>{abmusterung?.farbe_batch_nummer}</td>
            <td className={styles.border}>Farb-Nr.:</td>
            <td colSpan={2} className={styles.border}>{abmusterung?.farbe_nummer}</td>
          </tr>
          <tr>
            <th className={styles.right}>Gewicht [g]:</th>
            <td className={styles.border}>{formatNumber(abmusterung?.artikel_gewicht, 1)}</td>
          </tr>
          <tr>
            <th className={styles.right}>Stückzahl:</th>
            <td className={styles.border}>{formatNumber(abmusterung?.menge_produktionsauftrag)}</td>
          </tr>
          <tr className={styles.section_row} />
          <tr>
            <th className={styles.dotted_bottom_border_gray_bg}>PRODUKTION</th>
            <th className={styles.fat_border_dark_bg}>Produktion</th>
          </tr>
          <tr>
            <th className={styles.right}>PAU-Nr.:</th>
            <td className={styles.border_bottom}>{abmusterung?.produktionsauftrag_nummer}</td>
            <td />
            <th className={styles.left}>BLM:</th>
            <td className={styles.border_bottom}>{abmusterung?.blasmaschine_nummer}</td>
          </tr>
          <tr>
            <th className={styles.right}>Einrichter:</th>
            <td className={styles.border_bottom} />
            <td />
            <th className={styles.left}>Datum:</th>
            <td className={styles.border_bottom} />
          </tr>
          <tr>
            <th className={styles.right}>Verpacker:</th>
            <td className={styles.border_bottom} />
            <td />
            <th className={styles.left}>Datum:</th>
            <td className={styles.border_bottom} />
          </tr>
          <tr className={styles.spacer_row} />
          <tr className={styles.section_row} />
          <tr>
            <th className={styles.dotted_bottom_border_gray_bg}>QS</th>
            <th className={styles.fat_border_dark_bg}>QS</th>
          </tr>
          <tr>
            <th className={styles.right}>Freigabe:</th>
          </tr>
          <tr>
            <th className={styles.right}>Begleitschreiben:</th>
            <td><div className={styles.flex}>dabei <div className={styles.check_box} /></div></td>
          </tr>
          <tr>
            <th className={styles.right}>Meßprotokoll:</th>
            <td>per e-mail</td>
            <td />
            <th className={styles.left}>Datum:</th>
            <td colSpan={2} className={styles.border_bottom} />
          </tr>
          <tr>
            <th className={styles.right}>Bemerkung:</th>
            <td colSpan={5} className={styles.border_bottom} />
          </tr>
          <tr className={styles.spacer_row} />
          <tr className={styles.section_row} />
          <tr>
            <th className={styles.dotted_bottom_border_gray_bg}>Musterversand</th>
            <th className={styles.fat_border_dark_bg}>Versender</th>
          </tr>
          <tr>
            <th className={styles.right}>am:</th>
            <td className={styles.border_bottom} />
          </tr>
          <tr>
            <th className={styles.right}>von:</th>
            <td className={styles.border_bottom} />
          </tr>
          <tr className={styles.spacer_row} />
          <tr className={styles.section_row} />
          <tr>
            <th className={styles.dotted_bottom_border_gray_bg}>AUFTRAG</th>
            <th className={styles.fat_border_dark_bg}>Auftragsabwicklung</th>
          </tr>
          <th>Rechnung erstellt:</th>
          <td><div className={styles.flex}>ja <div className={styles.check_box} /> nein <div className={styles.check_box} /></div></td>
          <td />
          <td colSpan={2} className={styles.border_bottom} />
          <tr className={styles.spacer_row}>
            <td colSpan={3} />
            <td>Datum</td>
            <td>Unterschrift</td>
          </tr>
          <tr>
            <th colSpan={3} className={styles.footer_hinweis}>Nach Abmusterung zurück ans Planungsbüro</th>
          </tr>
          <tr className={styles.spacer_row} />
        </tbody>
      </HTMLTable>
    </div>
  );
}
